import { Directive, Host, Input, OnInit, Optional, TemplateRef } from '@angular/core';

export enum ModalSlotType {
  header = 'header',
  aside = 'aside',
  scrollableContent = 'scrollableContent',
  content = 'content',
  footer = 'footer'
}

@Directive({
  selector: '[modalSlot]'
})
export class ModalSlotDirective {

  @Input()
  modalSlot: string;

  constructor(@Optional() @Host() public templateRef: TemplateRef<any>) {
  }
}
