import { Component, ContentChildren, HostBinding, HostListener, Input, QueryList } from '@angular/core';
import { ModalSlotDirective, ModalSlotType } from './modal-slot.directive';
import { BaseModalComponent } from '@util/services/util-modal-types';


export interface StandardizedModalComponentOptions {
  ref?: BaseModalComponent<any, any>;
  header?: string;
  headerTranslationKey?: string;
}

@Component({
  selector: 'app-standardized-modal',
  templateUrl: './standardized-modal.component.html',
  styleUrls: ['./standardized-modal.component.scss']
})
export class StandardizedModalComponent {

  /** Controls a css class to hide focus visible CSS rules */
  @HostBinding('class.mouse-focus') mouseFocus = false;

  @HostListener('mousedown') handleMousedown() {
    this.mouseFocus = true;
  }

  @HostListener('keydown', ['$event']) handleKeydown(event: KeyboardEvent) {
    this.mouseFocus = !this.isUserKeydownEventForNavigating(event);
  }

  private _slots: ModalSlotDirective[] = [];
  private _options: StandardizedModalComponentOptions;

  headerModalSlot: ModalSlotDirective;
  asideModalSlot: ModalSlotDirective;
  contentModalSlot: ModalSlotDirective;
  scrollableContentModalSlot: ModalSlotDirective;
  footerModalSlot: ModalSlotDirective;

  protected unknownModalSlotHandler: (slotType: string, slot?: ModalSlotDirective) => void;

  @ContentChildren(ModalSlotDirective, { descendants: false })
  private set slotsQueryList(value: QueryList<ModalSlotDirective>) {
    this._slots = [];

    value?.forEach(slot => {
      const slotType = slot?.modalSlot as ModalSlotType;
      this._slots.push(slot);

      switch(slotType) {
        case ModalSlotType.header: this.headerModalSlot = slot; break;
        case ModalSlotType.aside: this.asideModalSlot = slot; break;
        case ModalSlotType.content: this.contentModalSlot = slot; break;
        case ModalSlotType.scrollableContent: this.scrollableContentModalSlot = slot; break;
        case ModalSlotType.footer: this.footerModalSlot = slot; break;
        default: {
          this.unknownModalSlotHandler?.(slotType, slot);
        } break;
      }
    });
  }

  @Input()
  ref: BaseModalComponent<any, any>;

  @Input()
  set options(value: StandardizedModalComponentOptions) {
    this._options = value;
  }

  get options(): StandardizedModalComponentOptions {
    return this._options;
  }

  close() {
    const modalRef = (this.ref || this.options?.ref);
    if (modalRef) {
      modalRef.closeModal();
    } else {
      console.warn('Modal cannot be closed because StandardizedModalComponent has no reference for the modal');
    }
  }

  protected isUserKeydownEventForNavigating(event: KeyboardEvent): boolean {
    // when the cmd or ctrl keys are used, the user doesn't navigate the storefront
    if (event.metaKey) {
      return false;
    }
    // when the tab key is used, users are for navigating away from the current (form) element
    if (event.code === 'Tab') {
      return true;
    }
    // If the user fill in a form, we are not considering it as part of storefront navigation.
    if (['INPUT', 'TEXTAREA'].includes((event.target as HTMLElement).tagName)) {
      return false;
    }
    return true;
  }
}
