<!-- conditional content projection - https://angular.io/guide/content-projection#conditional-content-projection-->

<header class="standardized-modal-header" role="heading">

  <ng-template #optionsHeader >
    <h1 *ngIf="options?.headerTranslationKey">{{options?.headerTranslationKey | cxTranslate}}</h1>
    <h1 *ngIf="!options?.headerTranslationKey">{{options?.header}}</h1>
  </ng-template>

  <ng-container *ngIf="headerModalSlot?.templateRef; else optionsHeader">
    <ng-container *ngTemplateOutlet="headerModalSlot.templateRef"></ng-container>
  </ng-container>

  <ng-template #defaultAside>
    <aside class="standardized-modal-aside">
      <app-icon-button
        iconType="CLOSE"
        colorTheme="BLACK"
        size="EXTRA_SMALL"
        (activateElement)="close()"
      ></app-icon-button>
    </aside>
  </ng-template>

  <ng-container *ngIf="asideModalSlot?.templateRef; else defaultAside">
    <aside class="standardized-modal-aside">
      <ng-container *ngTemplateOutlet="asideModalSlot.templateRef"></ng-container>
    </aside>
  </ng-container>

</header>

<section class="standardized-modal-section">

  <ng-container *ngIf="contentModalSlot?.templateRef">
    <ng-container *ngTemplateOutlet="contentModalSlot.templateRef"></ng-container>
  </ng-container>

</section>

<footer *ngIf="footerModalSlot?.templateRef" class="standardized-modal-footer">
  <ng-container *ngTemplateOutlet="footerModalSlot.templateRef"></ng-container>
</footer>
